<template>
  <div>
    <MobileHeader v-show="!isHideHeaderAndFooter && !isHideHeader" />
    <slot />
    <MenuBottom v-if="!isHideHeaderAndFooter" @display-search-page="displaySearchPage" />
    <client-only v-if="!isBotChromeLighthouse">
      <PageAccount v-if="isLogged" />
      <PageDeposit v-if="isLogged" />
      <PageWithdraw v-if="isLogged" />
      <ModalLogin v-if="!isLogged" />
      <ModalResetPassword v-if="!isLogged" />
      <ModalBlockGame v-if="isLogged" />
      <ModalChangeDisplayName v-if="isLogged" />
      <MiniGame />
    </client-only>
    <div class="opacity" @click="removeMenu" />
    <div v-if="isShowSearchPage" class="search-page">
      <PageSearch />
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import MobileHeader from '~/components/mobile/header/index.vue'
import MenuBottom from '~/components/mobile/common/menu-bottom/index.vue'
import ModalLogin from '~/components/common/modals/login/index.vue'
import ModalResetPassword from '~/components/common/modals/reset-password/index.vue'
import PageAccount from '~/components/mobile/common/page-account/index.vue'
import PageDeposit from '~/components/mobile/common/page-deposit/index.vue'
import PageWithdraw from '~/components/mobile/common/page-withdraw/index.vue'
import PageSearch from '~/components/mobile/pages/search/index.vue'
import MiniGame from '~/components/common/mini-game.vue'
import { useAppStore } from '~/store/app'
import { useRefresh } from '~/composables/user/useRefresh'
import { useInitData } from '~/composables/user/useInitData'
import { useJackpot } from '~/composables/game/useJackpot'
import { PAGE_HIDE_HEADER_FOOTER_MB, PAGE_HIDE_HEADER_MB } from '~/constants/common'
import { useToggleMenu } from '~/composables/useToggleMenu'
import useModal from '~/composables/useModal'

const ModalBlockGame = defineAsyncComponent(() => import('~/components/desktop/modals/block-game/index.vue'))
const ModalChangeDisplayName = defineAsyncComponent(
  () => import('~/components/desktop/modals/change-display-name/index.vue')
)
const { $pinia } = useNuxtApp()
const route = useRoute()
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
const { refreshTokenInterval, refreshToken } = useRefresh()
const { fetchInitData, fetchPlan } = useInitData()
const { removeMenu } = useToggleMenu()
const { closeAllModal } = useModal()
const { startJackpotEvent } = useJackpot()
const { isBotChromeLighthouse } = useAppStore()
const isShowSearchPage = ref(false)

const showSplash = ref(false)

onMounted(() => {
  startJackpotEvent()
  checkSplash()
  refreshToken()
  if (isLogged.value) {
    userInitData()
    removeParams()
  }

  window.addEventListener('popstate', handlePopstate)
})

onBeforeUnmount(() => {
  window.removeEventListener('popstate', handlePopstate)
})

watch(isLogged, () => {
  refreshToken()
  if (isLogged.value) {
    userInitData()
    removeParams()
  }
})

const userInitData = () => {
  refreshTokenInterval()
  fetchInitData()
  fetchPlan()
}

const disableBodyScroll = () => {
  document.body.style.overflow = 'hidden'
  document.body.style.touchAction = 'none'
}
const enableBodyScroll = () => {
  document.body.style.overflow = ''
  document.body.style.touchAction = ''
}
const displaySearchPage = (isShow: boolean) => {
  isShowSearchPage.value = isShow
  if (isShow) {
    disableBodyScroll()
  } else {
    enableBodyScroll()
  }
}
watch(() => route.query.search, (newSearch) => {
  if (newSearch !== undefined) {
    isShowSearchPage.value = true
    disableBodyScroll()
  } else {
    isShowSearchPage.value = false
    enableBodyScroll()
  }
})
onMounted(() => {
  isShowSearchPage.value = route.query.search !== undefined
  if (isShowSearchPage.value) { disableBodyScroll() }
})
onUnmounted(() => {
  enableBodyScroll()
})

const handlePopstate = () => {
  // close all modals except modal authentication
  if (!route.query.popup) {
    closeAllModal()
    // remove style and class of body when opening modal
    const bodyElement = document.querySelector('body')
    bodyElement?.classList.remove('modal-open')
    bodyElement?.removeAttribute('style')
  }
}

const isHideHeaderAndFooter = computed(() => {
  return (
    PAGE_HIDE_HEADER_FOOTER_MB.includes(route?.name as string) || PAGE_HIDE_HEADER_FOOTER_MB.includes(route.fullPath)
  )
})

const isHideHeader = computed(() => {
  return PAGE_HIDE_HEADER_MB.includes(route?.name as string)
})

const removeParams = () => {
  const query = { ...route.query }
  delete query.popup
  delete query.token
  navigateTo({
    query: { ...query }
  })
}

watch(isHideHeaderAndFooter, (newValue) => {
  if (process.client) {
    document.body.classList.toggle('mobile-custom', isHideHeaderAndFooter.value)
  }
}, { immediate: true })

const checkSplash = () => {
  const splashCookie = useCookie('splash_screen_shown')
  if (!splashCookie.value) {
    showSplash.value = true
    setTimeout(() => {
      showSplash.value = false
      const cookie = useCookie('splash_screen_shown', { maxAge: 12 * 60 * 60 })
      cookie.value = 'true'
    }, 3000)
  }
}
</script>
<style src="public/assets/scss/layout/mobile.scss" scoped lang="scss" />
