<template>
  <div class="recently-games">
    <div class="title">Game vừa chơi</div>
    <div class="recently-games__content">
      <div
        ref="banner"
        class="mySwiper"
      >
        <div
          class="swiper-slide"
          :class="{'more-slide': totalPage > 1}"
        >
          <div class="grid-games">
            <CommonGamesItemGame
              v-for="(game, index) in getCurrentList()"
              :key="index"
              class="game-item"
              :item="game"
              :show-tag="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'swiper/swiper-bundle.css'
import CommonGamesItemGame from '~/components/common/game-item.vue'

const props = defineProps({
  games: {
    type: Array,
    default: () => []
  }
})

const numberOfItemPerPage = 9
const totalPage = computed(() => Math.ceil(props.games.length / numberOfItemPerPage))
const getCurrentList = () => {
  return props.games.slice(0, numberOfItemPerPage)
}

</script>

<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/search/recently-games.scss" />
