<template>
  <div class="search-game-list">
    <div v-if="isLoading && page === 1" class="search-game-list__loading">
      <p>Đang tìm kiếm</p>
      <div class="empty-grid">
        <BaseImg
          v-for="index in totalLoadingItem"
          :key="index"
          class="game-item"
          :src="`${PATH_BRAND}default.webp`"
          alt="default thumb"
        />
      </div>
    </div>
    <div v-else-if="games.length" class="search-game-list__content">
      <div class="title">{{ `${totalGames} kết quả tìm kiếm` }}</div>
      <div class="grid-games">
        <CommonGamesItemGame
          v-for="(game, index) in games"
          :key="index"
          class="game-item"
          :item="game"
          :show-tag="false"
        />
        <ClientOnly>
          <InfiniteLoading
            v-if="isLoadMore"
            :distance="200"
            @infinite="($state) => $emit('load-more', $state)"
          >
            <template #spinner>
              <span />
            </template>
            <template #complete>
              <span />
            </template>
          </InfiniteLoading>
        </ClientOnly>
      </div>
    </div>
    <div v-else class="search-game-list__no-result">
      <div class="text-no-result">
        <span>Không tìm thấy kết quả cho</span>
        <span class="preformatted"> “{{ keyword }}”</span>
      </div>
      <KeywordSuggestions :data="suggestedKeywords" :on-press="onPress" />
    </div>
  </div>
</template>

<script setup lang="ts">
import InfiniteLoading from 'v3-infinite-loading'
import CommonGamesItemGame from '~/components/common/game-item.vue'
import KeywordSuggestions from '~/components/mobile/pages/search/keyword-suggestions.vue'
import { PATH_BRAND } from '~/constants/path'

const isListLoaded = ref(false)
const totalLoadingItem = 6

const hideKeyboard = () => {
  const activeElement = document.activeElement as HTMLElement
  if (activeElement && (activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA')) {
    activeElement.blur()
  }
}
const handleKeyPress = (event: KeyboardEvent): void => {
  if (event.key === 'Enter') {
    hideKeyboard()
  }
}

onMounted(() => {
  setTimeout(() => {
    isListLoaded.value = true
  }, 500)
  window.addEventListener('keyup', handleKeyPress)
})

onUnmounted(() => {
  scrollToTop()
  window.removeEventListener('keyup', handleKeyPress)
})

const scrollToTop = () => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, 100)
}

const props = defineProps({
  games: {
    type: Array,
    default: () => []
  },
  keyword: {
    type: String,
    default: ''
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  suggestedKeywords: {
    type: Array,
    default: () => []
  },
  onPress: {
    type: Function,
    default: () => {}
  },
  onLoadMore: {
    type: Function,
    default: () => {}
  },
  totalGames: {
    type: Number,
    default: 0
  },
  page: {
    type: Number,
    default: 0
  }
})

const isLoadMore = computed(() => {
  return props.games.length < props.totalGames
})

watch(isLoadMore, (newVal) => {
  setTimeout(() => {
    if (!newVal && props.games.length !== 0 && props.totalGames !== 0) {
      hideKeyboard()
    }
  }, 200)
})

</script>

<style scoped lang="scss" src="public/assets/scss/components/mobile/pages/search/game-list.scss" />
