<template>
  <div id="header-mobile" class="header">
    <BaseImg
      id="logo-mobile"
      class="logo"
      :relative-src="`${PATH_BRAND}logo.webp`"
      alt=""
      @click="$router.push(PAGE_URLS.HOME)"
    />
    <div class="header-button">
      <MobileHeaderUserNotLogin v-if="!currentUser" />
      <MobileHeaderUserLogged v-if="currentUser" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import MobileHeaderUserNotLogin from '~/components/mobile/header/user-not-login.vue'
import MobileHeaderUserLogged from '~/components/mobile/header/user-logged.vue'
import { useHeader } from '~/composables/useHeader'
import { useAppStore } from '@/store/app'
import { PAGE_URLS } from '~/config/page-url'
import { PATH_BRAND } from '~/constants/path'
const { currentUser } = useHeader()
const { fetchListBank } = useListBank()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { isLogged } = storeToRefs(store)
const $router = useRouter()

const jsStatus = ref(false)

watch(isLogged, () => {
  fetchListBank()
})

onMounted(async () => {
  jsStatus.value = true
  await fetchListBank()
})
</script>
<style scoped lang="scss" src="public/assets/scss/components/mobile/header/index.scss" />
